import React from "react";
import { Link } from "react-router-dom";

function Terms() {
  return (
    <div className="min-h-screen bg-white dark:bg-gray-900 text-gray-800 dark:text-gray-200 font-sans">
      <header className="py-6 px-4 text-center">
        <h1 className="text-3xl font-bold font-avenir">Terms of Agreement</h1>
      </header>
      <main className="px-4 py-8 max-w-3xl mx-auto">
        <p className="mb-4">
          Welcome to Kollektivet! These Terms of Agreement outline the rules and
          regulations for using our app. Please read them carefully.
        </p>
        <p className="mb-4">
          [Insert your detailed terms of agreement here...]
        </p>
        <Link
          to="/"
          className="mt-4 inline-block px-6 py-3 bg-[#ff375f] text-white font-semibold rounded-lg hover:bg-pink-600 transition-colors"
        >
          Back to Home
        </Link>
      </main>
    </div>
  );
}

export default Terms;
